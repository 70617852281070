// DraggingContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { UUID } from 'core/types';

interface DraggingContextProps {
  // TODO - make "isUserDragging" actually the UUID of the card so you don't show the dropzone of the dragged card
  isUserDragging: boolean;
  draggingNoteId: UUID | null;
  setDraggingNoteId: (noteId: UUID | null) => void;
  hoveringDropzoneKey: string | null;
  setHoveringDropzoneKey: React.Dispatch<React.SetStateAction<string | null>>;
}

const DraggingContext = createContext<DraggingContextProps | undefined>(undefined);

interface DraggingProviderProps {
  children: ReactNode;
}

export const DraggingProvider: React.FC<DraggingProviderProps> = ({ children }) => {
  const [draggingNoteId, setDraggingNoteId] = useState<UUID | null>(null);
  const [hoveringDropzoneKey, setHoveringDropzoneKey] = useState<string | null>(null);

  return (
    <DraggingContext.Provider
      value={{
        isUserDragging: !!draggingNoteId,
        draggingNoteId,
        setDraggingNoteId,
        hoveringDropzoneKey,
        setHoveringDropzoneKey,
      }}
    >
      {children}
    </DraggingContext.Provider>
  );
};

export const useDragging = (): DraggingContextProps => {
  const context = useContext(DraggingContext);
  if (!context) {
    throw new Error('useDragging must be used within a DraggingProvider');
  }
  return context;
};
