/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { dexieDB } from 'core/dbs/dexieDb';
import cytoscape, { Core } from 'cytoscape';
import fcose from 'cytoscape-fcose';
import { colors } from 'core/styles/colors';
import { ShownIdsContext } from 'src/context/ShownContext';
import { Note, UUID } from 'core/types';

cytoscape.use(fcose);

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-size: 10px 10px;
  background-position: 0 0;
  overflow: auto;
  justify-content: center;
`;

interface BriefNote {
  id: UUID;
  title: string;
  links: string[];
  type: string;
}

const GraphSurface: React.FC = () => {
  const { shownIds } = React.useContext(ShownIdsContext);
  const cyRef = React.useRef<HTMLDivElement>(null);
  const cytoscapeInstanceRef = React.useRef<Core | null>(null);

  const [notes, setNotes] = React.useState<BriefNote[]>([]);

  React.useEffect(() => {
    // Fetch source notes and annote notes separately
    const fetchNotes = async (): Promise<void> => {
      const noteMap: { [key: string]: BriefNote } = {};

      // Fetch source notes and create a map
      await dexieDB.db.notes
        .where('type')
        .notEqual('annote')
        .each((note) => {
          noteMap[note.id] = {
            id: note.id,
            title: note.title || note.value.slice(0, 50),
            links: note.links || [],
            type: note.type,
          };
        });

      // Fetch annotes and roll up links to their corresponding source notes
      await dexieDB.db.notes
        .where('type')
        .equals('annote')
        .each((annote) => {
          if (annote.links.length === 0) return;
          const sourceId = annote.createdFromId;
          if (!sourceId || !noteMap[sourceId]) return;
          noteMap[sourceId].links = [...noteMap[sourceId].links, ...annote.links];
        });

      // Ensure all links are unique and convert map to array
      const formattedNotes = Object.values(noteMap).map((note) => {
        // eslint-disable-next-line no-param-reassign
        note.links = Array.from(new Set(note.links));
        return note;
      });

      setNotes(formattedNotes);
    };

    fetchNotes();
  }, []);

  React.useEffect(() => {
    if (cyRef.current) {
      const noteIds = new Set(notes.map((note) => note.id));
      const elements = notes.flatMap((note) => {
        const nodes = { data: { id: note.id, label: note.title, type: note.type } };
        const edges = note.links
          .filter((link) => noteIds.has(link))
          .map((link) => ({ data: { source: note.id, target: link } }));
        return [nodes, ...edges];
      });

      const cy = cytoscape({
        container: cyRef.current,
        elements,
        style: [
          {
            selector: 'node[type="takeaway"]',
            style: {
              shape: 'rectangle',
              width: '200px', // Width of the rectangle - 40px per inch
              height: '120px', // Height of the rectangle
              'background-color': colors.bg.primary,
              label: 'data(label)',
              'text-valign': 'center',
              'text-halign': 'center',
              color: colors.text.primary, // Label color
              'font-size': '14px',
              'border-width': 2,
              'border-color': colors.lines.indexRed,
              'text-wrap': 'wrap', // Enable text wrapping
              'text-max-width': '180px', // Max width for text to wrap within the node
            },
          },
          {
            selector: 'node[type="source"]',
            style: {
              shape: 'rectangle',
              width: '340px', // Width of the rectangle
              height: '440px', // Height of the rectangle
              'background-color': colors.bg.primary,
              label: 'data(label)',
              'text-valign': 'center',
              'text-halign': 'center',
              color: colors.text.primary, // Label color
              'font-size': '14px',
              'border-width': 2,
              'border-color': colors.lines.link,
              'text-wrap': 'wrap', // Enable text wrapping
              'text-max-width': '320px', // Max width for text to wrap within the node
            },
          },
          {
            selector: 'edge',
            style: {
              width: 3,
              'line-color': colors.lines.link,
              'target-arrow-color': colors.lines.link,
              'target-arrow-shape': 'triangle',
            },
          },
        ],

        layout: {
          name: 'fcose',
          quality: 'proof', // Use high quality layout
          randomize: false, // Do not randomize initial positions
          animate: 'end', // When the layout should animate (during the layout or at the end)
          animationDuration: 1000, // Duration of animation in ms, if enabled
          fit: true, // Whether to fit the network view after when done
          padding: 50, // Padding on fit
          nodeSeparation: 500, // Node separation between nodes
          nodeRepulsion: 15000, // Node repulsion (non-overlapping) multiplier
          idealEdgeLength: 200, // Ideal edge (non-overlapping) length
          edgeElasticity: (edge: any) => 0.1, // Divisor to compute edge forces
          gravity: 0.05, // Gravity force (constant)
          numIter: 2500, // Maximum number of iterations to perform
          tile: true, // Whether to tile disconnected components
          tilingPaddingVertical: 50, // Vertical padding for tiling
          tilingPaddingHorizontal: 50, // Horizontal padding for tiling
          gravityRangeCompound: 1.5, // Gravity range for compounds
          gravityCompound: 1.0, // Gravity force for compounds
          gravityRange: 3.8, // Gravity range (constant)
          initialEnergyOnIncremental: 0.5, // Initial cooling factor for incremental layout
        } as any,
      });

      // Store the Cytoscape instance in the ref
      cytoscapeInstanceRef.current = cy;
    }
  }, [notes]);

  useEffect(() => {
    // Zoom to the first note in the list
    const id = shownIds[0];
    console.log('Zooming to', id, cytoscapeInstanceRef.current);
    if (cytoscapeInstanceRef.current) {
      const cy = cytoscapeInstanceRef.current;
      const node = cy.getElementById(id);
      if (node) {
        cy.zoom({
          level: 2, // Zoom level
          position: { x: node.position('x'), y: node.position('y') },
        });
        cy.center(node);
      }
    }
  }, [shownIds]);

  return (
    <Surface id="surface" ref={cyRef}>
      <p>We are drawing a graph with {notes?.length} notes.</p>
    </Surface>
  );
};

export default GraphSurface;
