import { useContext, ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import ComingSoon from 'src/routes/ComingSoon';
import { ProfileContext } from '../context/ProfileContext';

const nonRedirectPaths = ['/login', '/soon', '/logout'];

const RequireAuth = ({ children }: { children: ReactNode }): React.ReactElement => {
  const { profile } = useContext(ProfileContext);
  const location = useLocation();
  if (!profile) {
    if (location.pathname === '/' && !(location.search.includes('ids=') || location.search.includes('url=')))
      return <ComingSoon />;

    // Save the path they were trying to go to for redirect after login
    if (!nonRedirectPaths.includes(location.pathname)) {
      localStorage.setItem('redirectTo', location.pathname + location.search);
    }
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
