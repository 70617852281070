import React, { useEffect, useState, useRef } from 'react';
import { supabaseClient } from 'src/db/supabase';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContext';

const CreateUserLogin: React.FC = () => {
  const navigate = useNavigate();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const hasRun = useRef(false); // Add a ref to track if the effect has already run
  const { getAndSetSession } = useAuthContext();

  useEffect(() => {
    const createUserLogin = async (): Promise<void> => {
      if (isSigningUp || hasRun.current) return; // Prevent the effect from running twice
      setIsSigningUp(true);
      hasRun.current = true; // Mark that the effect has run

      const randomKey = Math.random().toString(36).substring(2, 15);
      const uniqueEmail = `dave+${randomKey}@annote.com`;
      const password = '$uper$ecurePassw0rd'; // Use a fixed password for testing

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay

      console.log('Creating user with email:', uniqueEmail);
      const { data, error } = await supabaseClient.auth.signUp({
        email: uniqueEmail,
        password,
      });

      if (error) {
        console.error('Error during user sign-up', error);
      } else {
        console.log('User sign-up data', data);
        await getAndSetSession();
        if (data.user) navigate('/'); // Redirect to home if sign-up is successful
        else console.error('No user data returned after sign-up');
      }
    };

    createUserLogin();
  }, []);

  return <div>Creating a new user... This will take 5 seconds</div>;
};

export default CreateUserLogin;
