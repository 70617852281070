import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { supabaseClient } from 'src/db/supabase';
import { GOOGLE_CLIENT_ID } from 'src/config/general';
import { useAuthContext } from 'src/context/AuthContext';
import { useProfileContext } from 'src/context/ProfileContext';

const AuthWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  justify-content: center;
`;

interface CredentialResponse {
  credential: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();
  const { getAndSetSession, session } = useAuthContext();
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  // Callback for when the user signs in with Google
  const handleCredentialResponse = async (response: CredentialResponse): Promise<void> => {
    const idToken = response.credential;
    console.log('Application doing supabase signin');
    const { data, error } = await supabaseClient.auth.signInWithIdToken({
      provider: 'google',
      token: idToken,
    });
    if (error) {
      console.error('Error during Supabase sign-in', error);
      return;
    }
    if (data) {
      console.log('Callback success. Now get and set session', data);
      // We should be authed!  trigger getAndSetSession, and profile should get set
      await getAndSetSession();
    }
  };

  useEffect(() => {
    const loadGoogleScript = (): void => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => setIsGoogleScriptLoaded(true);
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      setIsGoogleScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (session) {
      console.log('session is set - going to redirect soon', session);
      return;
    }
    // Not yet logged in - Setup Google Sign-In
    if (isGoogleScriptLoaded && window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      console.log('rendering google signin button');
      // Render the Google Sign-In button
      const button = document.getElementById('google-signin-button');
      if (button) {
        window.google.accounts.id.renderButton(button, { theme: 'outline', size: 'large' });
      } else {
        console.error('Google Sign-In button element not found');
      }
    }
  }, [session, isGoogleScriptLoaded]);

  // Wait till there's a profile (meaning we're authed) and then redirect
  // Profile should get populated after getAndSetSession is called if authed
  useEffect(() => {
    console.log('profile changed', profile);
    if (!profile) return;
    console.log('profile is set', profile);
    const redirectTo = localStorage.getItem('redirectTo');
    localStorage.removeItem('redirectTo');
    navigate(redirectTo || '/');
  }, [profile, navigate]);

  return (
    <AuthWrapper>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-login_uri={`${window.location.origin}/login`}
        data-auto_select="true"
      ></div>
      <div id="google-signin-button"></div>
    </AuthWrapper>
  );
};

export default Login;
