// Shown Context handles state of which cards are shown
import React, { useEffect, useState, ReactNode, useContext, useCallback } from 'react';
import { UUID, NoteMap, Note, Source } from 'core/types';
import { useLiveQuery } from 'dexie-react-hooks';
import { scrollToNote } from 'src/helpers/scrolling';
import { BrainContext } from './BrainContext';
import { FocusContext } from './FocusContext';

type ShownIdsContextType = {
  shownIds: UUID[];
  surfaceNoteMap: NoteMap;
  readerURL: string | null;
  show: (noteId: UUID | UUID[]) => void;
  showAafterB: (a: UUID | UUID[], b?: UUID) => void;
  showAbeforeB: (a: UUID | UUID[], b?: UUID) => void;
  hide: (noteId: UUID | UUID[], ignoreSources?: boolean) => void;
  clear: () => void;
  clearReaderUrl: (url: string) => void;
};

const defaultShownIdsContext: ShownIdsContextType = {
  shownIds: [],
  readerURL: null,
  surfaceNoteMap: {},
  show: () => {},
  showAafterB: () => {},
  showAbeforeB: () => {},
  hide: () => {},
  clear: () => {},
  clearReaderUrl: () => {},
};

// Unfortunately this is necessary for the comparison of the live query results
// TODO - find a better way
const areArraysEqual = (a: UUID[], b: UUID[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements, you can sort both arrays before comparing
  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const ShownIdsContext = React.createContext<ShownIdsContextType>(defaultShownIdsContext);

type ShownIdsProviderProps = {
  children: ReactNode;
};

export const ShownIdsProvider: React.FC<ShownIdsProviderProps> = ({ children }) => {
  const [shownIds, setShownIds] = useState<UUID[]>([]);
  const [readerUrl, setReaderUrl] = useState<UUID | null>(null);
  const [surfaceIds, setSurfaceIds] = useState<UUID[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const { getNoteMapByIds, getNoteByUrl } = useContext(BrainContext);
  const { setFocus } = useContext(FocusContext);

  // adds the noteId (or list of noteIds) to the front of shownIds
  const show = useCallback(
    (noteId: UUID | UUID[]): void => {
      const idsToShow = Array.isArray(noteId) ? noteId : [noteId];
      setShownIds((currentShownIds) => {
        const cleanIds = currentShownIds.filter((id) => !idsToShow.includes(id));
        return [...idsToShow, ...cleanIds];
      });
    },
    [setShownIds],
  );

  // hook to clear the reader url if the source is minimized
  const clearReaderUrl = useCallback(
    (url: string): void => {
      if (readerUrl === url) setReaderUrl(null);
    },
    [readerUrl, setReaderUrl],
  );

  const readerUrlSource = useLiveQuery(() => {
    if (!readerUrl) return null;
    return getNoteByUrl(readerUrl!);
  }, [readerUrl]) as Source | null;

  useEffect(() => {
    if (readerUrlSource?.id && !shownIds.includes(readerUrlSource.id)) {
      show(readerUrlSource.id);
    }
  }, [readerUrl, readerUrlSource]);

  const showAafterB = useCallback(
    (a: UUID | UUID[], b?: UUID): void => {
      if (!b) {
        show(a);
        return;
      }
      const idsToAdd = Array.isArray(a) ? a : [a];
      if (idsToAdd.length === 1 && idsToAdd[0] === b) return; // same spot - don't move
      setShownIds((currentShownIds) => {
        const shownIdsWithoutA = currentShownIds.filter((id) => !idsToAdd.includes(id)); // Note B must exist
        const indexOfB = shownIdsWithoutA.indexOf(b);
        const beforeB = shownIdsWithoutA.slice(0, indexOfB + 1);
        const afterB = shownIdsWithoutA.slice(indexOfB + 1);
        return [...beforeB, ...idsToAdd, ...afterB];
      });
    },
    [setShownIds],
  );

  const showAbeforeB = useCallback(
    (a: UUID | UUID[], b?: UUID): void => {
      if (!b) {
        show(a);
        return;
      }
      const idsToAdd = Array.isArray(a) ? a : [a];
      setShownIds((currentShownIds) => {
        const shownIdsWithoutA = currentShownIds.filter((id) => !idsToAdd.includes(id)); // Note B must exist
        const indexOfB = shownIdsWithoutA.indexOf(b);
        const beforeB = shownIdsWithoutA.slice(0, indexOfB);
        const afterB = shownIdsWithoutA.slice(indexOfB);
        return [...beforeB, ...idsToAdd, ...afterB];
      });
    },
    [setShownIds],
  );

  const clear = useCallback((): void => {
    setShownIds([]);
    setReaderUrl(null);
  }, [setShownIds, setReaderUrl]);

  // Set the initial shownIds from the URL after the component mounts
  useEffect(() => {
    if (isInitialized) return;
    const params = new URLSearchParams(window.location.search);
    const shownIdsFromUrl = (params.get('ids') || '').split(',').filter((id) => id !== '');
    setReaderUrl(params.get('url') || null);
    setIsInitialized(true);

    // Find the id of the note in the URL and move it to the front of the shownIds
    if (shownIdsFromUrl.length === 0) return;
    show(shownIdsFromUrl);
  }, []);

  // Update the Window Location whenever the shownIds change
  useEffect(() => {
    if (!isInitialized) return;
    const params = new URLSearchParams(window.location.search);
    if (!readerUrl) params.delete('url');
    params.delete('ids');
    if (shownIds.length > 0) params.set('ids', shownIds.join(','));
    const paramsString = params.toString() ? `?${params.toString()}` : '';
    // TODO - can change to pushState if we want to allow back button
    // but then we also have to listen to the popstate event
    window.history.replaceState({}, '', `${window.location.pathname}${paramsString}`);
  }, [shownIds, readerUrl]);

  // Just the shown notes - see surfaceNoteMap for all shown and supporting notes
  const shownNoteMap =
    useLiveQuery(
      () => {
        return getNoteMapByIds(shownIds);
      },
      [JSON.stringify(shownIds)], // Use a stable dependency
    ) || ({} as NoteMap);

  // Control the state list of all Ids needed for the surface
  useEffect(() => {
    const connectedIds = Object.values(shownNoteMap).flatMap((n: Note) => [...(n.links || []), ...(n.backlinks || [])]);
    const shownIdsFromMap = Object.keys(shownNoteMap);
    const uniqueSurfaceIds = Array.from(new Set([...shownIdsFromMap, ...connectedIds])).sort();
    if (areArraysEqual(surfaceIds, uniqueSurfaceIds)) return;
    setSurfaceIds(uniqueSurfaceIds);
  }, [shownNoteMap]);

  // A map of all of the shown and hidden notes used for the surface
  const surfaceNoteMap = useLiveQuery(() => getNoteMapByIds(surfaceIds), [surfaceIds]) || {};

  // Make sure all sources of any annote in surfaceNoteMap are also in surfaceIds (and therefore the map)
  useEffect(() => {
    const annotes = Object.values(surfaceNoteMap).filter((n) => n.type === 'annote');
    const sourceIds = new Set(annotes.map((n) => n.createdFromId!));
    const unlistedSources = Array.from(sourceIds).filter((id) => !surfaceIds.includes(id));
    if (unlistedSources.length === 0) return;
    setSurfaceIds((currentSurfaceIds) => [...currentSurfaceIds, ...unlistedSources]);
  }, [surfaceNoteMap, surfaceIds, setSurfaceIds]);

  const hide = useCallback(
    (noteId: UUID | UUID[], ignoreSources = false): void => {
      const idsToRemove = Array.isArray(noteId) ? noteId : [noteId];
      if (readerUrlSource?.id && idsToRemove.includes(readerUrlSource.id)) {
        console.log('Hiding the reader URL', readerUrlSource.id);
        setReaderUrl(null);
        setTimeout(() => {
          // This is a hack to prevent the reader from being shown again
          setShownIds((currentShownIds) => currentShownIds.filter((id) => id !== readerUrlSource.id));
        }, 10);
      }

      if (!ignoreSources) {
        // Add the ids of sources to annotes to the list of ids to remove
        idsToRemove.forEach((id) => {
          const a = surfaceNoteMap[id];
          if (!a || a.type !== 'annote') return;
          idsToRemove.push(a.createdFromId!);
        });

        // add all of sources to the list of ids to remove
        idsToRemove.forEach((id) => {
          const s = surfaceNoteMap[id];
          if (!s || s.type !== 'source') return;
          const shownAnnotes = Object.values(surfaceNoteMap).filter((n) => n.createdFromId === s.id);
          idsToRemove.push(...shownAnnotes.map((n) => n.id));
        });
      }

      setShownIds((currentShownIds) => {
        const filteredIds = currentShownIds.filter((id) => !idsToRemove.includes(id));
        return filteredIds;
      });
    },
    [surfaceNoteMap, readerUrlSource, setReaderUrl, setShownIds],
  );

  // Handle when links in notes are clicked
  useEffect(() => {
    const handleLinkClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (!target.matches('a')) return;
      const href = target.getAttribute('href');

      if (!href) return;
      e.preventDefault();
      if (href.startsWith('http')) {
        window.open(href, '_blank');
        return;
      }
      // Focus on the internal link
      if (target.classList.contains('internal-link')) {
        const noteId = href;
        setFocus(noteId);
        if (!shownIds.includes(noteId)) show(noteId);
        // TODO probably a better listener for this than a timeout
        setTimeout(() => scrollToNote(noteId), 100);
      }
    };
    document.addEventListener('click', handleLinkClick);
    return () => document.removeEventListener('click', handleLinkClick);
  }, [show, shownIds, setFocus]);

  useEffect(() => {
    // If a source is fetched from an extension, show it
    window.addEventListener('message', (event) => {
      if (event.source !== window) return;
      if (event.data.action === 'sourceFetched') {
        const response = event.data;
        console.log('shownContext sourceFetched', response);
        if (response.showAfterFetch) {
          showAbeforeB(response.source.id, response.showAfterFetch);
        }
      }
    });
  }, []);

  return (
    <ShownIdsContext.Provider
      value={{
        shownIds,
        readerURL: readerUrl,
        show,
        showAafterB,
        showAbeforeB,
        hide,
        clear,
        surfaceNoteMap,
        clearReaderUrl,
      }}
    >
      {children}
    </ShownIdsContext.Provider>
  );
};
