// Toolbar.tsx
import styled from 'styled-components';
import { useContext } from 'react';
import { BrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { Icon, IconOption } from './Icon';
import NewCardButton from './NewCardButton';
import Library from './Library';

const ToolbarWrapper = styled.div<{ isToolbarExpanded: boolean }>`
  position: sticky;
  display: flex;
  z-index: 25;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 4rem;
  position: sticky;
  transform: ${(props) => (props.isToolbarExpanded ? 'translateX(0)' : 'translate(-41rem)')};
  margin-right: ${(props) => (props.isToolbarExpanded ? '0' : '-41rem')};
  transition: transform 0.3s ease-in-out, margin-right 0.3s ease-in-out;
`;

const ToolbarElementWrapper = styled.div`
  position: absolute;
  right: 0;
  transform: translateX(100%);
`;

const ToggleWrapper = styled(ToolbarElementWrapper)`
  top: 0;
  z-index: 21;
`;

const NewCardWrapper = styled(ToolbarElementWrapper)`
  top: 5rem;
`;

const BottomWrapper = styled(ToolbarElementWrapper)`
  bottom: 0;
`;

const ToolbarButton = styled.div`
  display: flex;
  padding: 1rem 1rem;
  width: 2rem;
  margin-left: 1rem;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
  background: #fafafa;
  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

const LibraryButton = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  height: 3rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  background: #fafafa;
  box-shadow: 1px 1px 2px -1px rgba(15, 15, 15, 0.16), 1px 0px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 21;
  position: relative;
`;

const StyledNewCardButton = styled(NewCardButton)`
  z-index: 10;
`;

interface ToolbarProps {
  isToolbarExpanded: boolean;
  toggleToolbar: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ isToolbarExpanded, toggleToolbar }) => {
  const { createTakeaway } = useContext(BrainContext);
  const { show, clear } = useContext(ShownIdsContext);

  // State for Library
  const newNote = async (): Promise<void> => {
    const createdNote = await createTakeaway();
    show(createdNote.id);
  };

  return (
    <ToolbarWrapper isToolbarExpanded={isToolbarExpanded} id="toolbar">
      <Library />
      <ToggleWrapper>
        <LibraryButton onClick={toggleToolbar}>
          <Icon type={IconOption.Archive} size="2rem" />
        </LibraryButton>
      </ToggleWrapper>
      <NewCardWrapper>
        <StyledNewCardButton onClick={newNote} />
      </NewCardWrapper>
      <BottomWrapper>
        <ToolbarButton onClick={clear}>
          <Icon type={IconOption.ClearBoard} size="2rem" />
        </ToolbarButton>
      </BottomWrapper>
    </ToolbarWrapper>
  );
};
export default Toolbar;
