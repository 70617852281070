import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Note, Source, Takeaway } from 'core/types';
import { colors } from 'core/styles';
import TakeawayCard from './_components/Takeaway';
import { SourceCard } from './_components/Source';

const PREVIEW_CARD_GAP_IN_PX = 35;

const PreviewCardWrapper = styled.div`
  position: absolute;
  z-index: 1010;
  background: ${colors.bg.primary};
  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
`;

type PreviewCardProps = {
  note: Note;
  triggerRef: React.RefObject<HTMLDivElement>; // the element that triggers the preview
  isTriggerHovered?: boolean;
  className?: string;
};

const PORTAL_ID = 'surface';

const PreviewCard: React.FC<PreviewCardProps> = ({ note, triggerRef, isTriggerHovered = false, className }) => {
  const [previewCoords, setPreviewCoords] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const setCoords = (): void => {
    // TODO consider having surfaceRef in context
    const surfaceRef = document.querySelector<HTMLDivElement>(`#${PORTAL_ID}`);
    if (!surfaceRef) throw new Error("Preview can't be rendered - Surface not found");
    const surfaceRect = surfaceRef?.getBoundingClientRect() || { top: 0, left: 0 };
    const { top, left, width } = triggerRef.current?.getBoundingClientRect() || { top: 0, left: 0, width: 0 };
    setPreviewCoords({
      top: top + (surfaceRef?.scrollTop ?? 0) - surfaceRect.top,
      left: left + (surfaceRef?.scrollLeft ?? 0) + width + PREVIEW_CARD_GAP_IN_PX - surfaceRect.left,
    });
  };

  useEffect(() => {
    setCoords();
  }, [isTriggerHovered, triggerRef?.current]);

  const modalRoot = document.querySelector<HTMLDivElement>(`#${PORTAL_ID}`);
  if (!modalRoot) {
    return null;
  }

  const preview = (
    <PreviewCardWrapper
      style={{ top: `${previewCoords.top}px`, left: `${previewCoords.left}px` }}
      className={className}
    >
      {note.type === 'takeaway' && <TakeawayCard key={`preview-${note.id}`} takeaway={note as Takeaway} />}
      {note.type === 'source' && <SourceCard key={`preview-${note.id}`} source={note as Source} inPreview />}
    </PreviewCardWrapper>
  );

  return ReactDOM.createPortal(preview, modalRoot);
};

export default PreviewCard;
