import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComingSoon from 'src/routes/ComingSoon';
import ThinkApp from './routes/ThinkApp/ThinkApp';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TreeSurface from './routes/ThinkApp/TreeSurface/TreeSurface';
import Login from './routes/Login';
import Logout from './routes/Logout';
import RequireAuth from './components/RequireAuth';
import FocusSurface from './routes/ThinkApp/FocusSurface/FocusSurface';
import GraphSurface from './routes/ThinkApp/GraphSurface';
import CreateUserLogin from './routes/CreateUserLogin';

const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/soon" element={<ComingSoon />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login/annon" element={<CreateUserLogin />} />
          {/* Authed Routes */}
          <Route
            path="/focus"
            element={
              <RequireAuth>
                <ThinkApp surface={<FocusSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/graph"
            element={
              <RequireAuth>
                <ThinkApp surface={<GraphSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/"
            element={
              <RequireAuth>
                <ThinkApp surface={<TreeSurface />} />
              </RequireAuth>
            }
          />
          <Route path="*" element={<div>Route not found</div>} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
