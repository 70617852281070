import { Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Note } from 'core/types';
import { createNote } from 'core/utils/notes';
import { colors, text } from 'core/styles';
import { getMarkdownFromView } from './utils';
import { getDB } from './db';

const linkSelectedPluginKey = new PluginKey('linkSelectedPlugin');

const hideLinkMenu = (): void => {
  const menu = document.getElementById('link-menu');
  if (menu) {
    menu.style.display = 'none';
  }
};

const createLink = async (view: EditorView, dispatch: (tr: Transaction) => void, note: Note): Promise<void> => {
  const { state } = view;
  const { from, to } = view.state.selection;
  // get the text of the link
  const linkText = state.doc.textBetween(from, to, ' ');
  console.log('text is', linkText);
  const newNote = (await getDB().getNoteByTitle(linkText)) || createNote(linkText, 'takeaway', '', note.id);

  // mark the selected text as a link to the newNote
  const markType = state.schema.marks.link;
  dispatch(state.tr.addMark(from, to, markType.create({ href: newNote.id, internal: true })));

  // update the note.value and links - and save!
  // eslint-disable-next-line no-param-reassign
  note.value = getMarkdownFromView(view);
  console.log('linking in new note from', note, 'to', newNote, note.value);
  note.links.push(newNote.id);
  newNote.backlinks.push(note.id);
  getDB().updateNotes([note, newNote]);
  hideLinkMenu();
};

const showLinkMenu = (
  rect: { left: number; right: number; top: number; bottom: number },
  view: EditorView,
  note: Note,
): void => {
  // TODO - make this link menu a component
  let menu = document.getElementById('link-menu');
  if (!menu) {
    // Create the menu if it doesn't exist
    menu = document.createElement('div');
    menu.id = 'link-menu';
    menu.innerText = 'Create Link';

    // Apply styles inline
    menu.style.position = 'absolute';
    menu.style.backgroundColor = colors.bg.primary;
    menu.style.border = `1px solid ${colors.borders.input.primary};`;
    menu.style.padding = '10px';
    menu.style.borderRadius = '4px';
    menu.style.fontFamily = text.family.primary;
    menu.style.color = colors.text.primary;
    menu.style.fontSize = text.size.small;
    menu.style.boxShadow = '0px 3px 6px rgba(15, 15, 15, 0.1)';
    menu.style.cursor = 'pointer';
    menu.style.zIndex = '1010';
    menu.style.display = 'block'; // Initially hidden

    document.body.appendChild(menu);
  }

  // Position the menu above the selected text
  menu.style.left = `${rect.left}px`;
  menu.style.top = `${rect.top - menu.offsetHeight}px`;
  menu.style.display = 'block';
  menu.onclick = () => {
    createLink(view, view.dispatch, note);
    hideLinkMenu();
  };
};

const linkSelectedPlugin = (options: { note: Note }): Plugin =>
  new Plugin({
    key: linkSelectedPluginKey,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    view: (editorView: EditorView) => ({
      update: (view: EditorView) => {
        const { state } = view;
        const { from, to } = state.selection;
        if (from !== to) {
          const selectionRect = view.coordsAtPos(from);
          console.log('show the link menu at cords', selectionRect);
          showLinkMenu(selectionRect, view, options.note);
        } else {
          hideLinkMenu();
        }
      },
    }),
  });

export default linkSelectedPlugin;
