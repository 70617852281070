import React from 'react';
import styled from 'styled-components';
import { Annote } from 'core/types';
import { ProseEditor } from 'src/components/ProseEditor';
import { Highlight, Wrapper, NoteWrapper } from './Annote';

const MAX_SMALL_CARD_CHARS = 53;

const SmallWrapper = styled(Wrapper)`
  width: 32rem;
  padding: 12px 12px 6px 12px;

  .ProseMirror {
    cursor: pointer;
    pointer-events: none;
  }
`;

export const SmallAnnoteCard: React.FC<{ annote: Annote }> = ({ annote }) => {
  if (!annote) return null;

  const getShortText = (textToShorten: string, maxLength: number): string => {
    if (!textToShorten) return '';
    return textToShorten.length > maxLength ? `${textToShorten.substring(0, maxLength)}...` : textToShorten;
  };

  // TODO - prosemirror doesn't have an option to give a shorter version so this will always be the long version...
  const shortNote = getShortText(annote.value, MAX_SMALL_CARD_CHARS);
  // Adjust quote length based on the length of the shortNote
  const quoteLen = MAX_SMALL_CARD_CHARS - shortNote.length;
  const shortQuote = getShortText(annote.title, quoteLen);

  return (
    <SmallWrapper>
      <Highlight>{shortQuote}</Highlight>
      <NoteWrapper>
        <ProseEditor note={annote} />
      </NoteWrapper>
    </SmallWrapper>
  );
};
