const pallet = {
  gray5: '#F5F5F5',
  gray10: '#E9E9E8',
  gray15: '#DBDBDA',
  gray25: '#C7C6C5',
  gray50: '#878683',
  gray60: '#5F5D59',
  gray80: '#4B4945',
  gray90: '#373530',

  lightBlue: '#E6E7F2',
  white: '#FFF',
  blue: '#5D6DD9',
  indexRed: '#FED7D7',

  // yellows (low number is light)
  yellow10: '#FFF9C9', // note yellows
  yellow20: '#FFF399',
  yellow30: '#FFEC66',

  yellow60: '#FFF192', // quote yellows
  yellow70: '#FFEA61',
  yellow80: '#FFD400',
};

export const colors = {
  text: {
    primary: pallet.gray90,
    header: pallet.gray90,
    placeholder: pallet.gray50,
    subtitle: pallet.gray50,
    description: pallet.gray80,
    button: pallet.gray60,
    link: pallet.blue,
  },

  bg: {
    primary: pallet.white,
    surface: pallet.gray5,
    hover: pallet.gray5,
    selected: pallet.gray10,
    dragging: pallet.gray10,
    dropzone: pallet.lightBlue,
    library: '#fafafa',
    // Annote backgrouns (note and quote)
    note: {
      primary: pallet.yellow10,
      hover: pallet.yellow20,
      focus: 'rgb(255, 212, 0, .6)', // pallet.yellow30,
    },
    quote: {
      primary: pallet.yellow60,
      hover: pallet.yellow70,
      focus: pallet.yellow80,
    },
  },

  lines: {
    indexRed: pallet.indexRed,
    link: pallet.gray15,
    selected: pallet.blue,
  },

  icon: {
    primary: pallet.gray80,
    placeholder: pallet.gray50,
  },

  borders: {
    input: {
      primary: pallet.gray10,
      selected: pallet.gray25,
    },
  },
};
