/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
import { SupabaseClient } from '@supabase/supabase-js';
import { Database, Note, TypeOfNote, UUID } from 'core/types';
import { IDatabase } from './types';

export class SupabaseDatabase implements IDatabase {
  db: SupabaseClient<Database>;

  userId?: UUID;

  private isUrlBlocked: (url: string) => boolean = () => false;

  constructor(db: SupabaseClient<Database>) {
    this.db = db;
  }

  // cache the userId and isURLBlocked function
  async initFromSelf(): Promise<void> {
    // Get the session and user
    const {
      data: { session },
      error,
    } = await this.db.auth.getSession();
    if (error) throw error;
    const user = session?.user;
    if (!user?.id) return;
    this.userId = user.id;

    // TODO - this is an unecessary fetch - we've already fetched this other ways
    // but for now it nicely keeps code seperate
    const { data: profile, error: err } = await this.db.from('Profiles').select().eq('userId', user.id).single();
    if (err) throw err;
    if (!profile) return;

    console.log('user and profile from supabase init from self', user, profile);
    const isUrlBlocked = (url: string): boolean => {
      const { host } = new URL(url);
      return profile?.blockedHosts.includes(host);
    };

    this.isUrlBlocked = isUrlBlocked;
  }

  async getNotes(): Promise<Note[]> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNotes');
    }
    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    return notes as Note[];
  }

  async getNotesByUrl(url: string): Promise<{ notes: Note[]; isBlocked: boolean }> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNotesByUrl');
    }
    if (this.isUrlBlocked && this.isUrlBlocked(url)) {
      return { notes: [], isBlocked: true };
    }
    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('url', url)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    return { notes: notes as Note[], isBlocked: false };
  }

  async getNoteById(id: UUID): Promise<Note | null> {
    if (!this.userId) {
      throw new Error('User not authenticated - getNoteById');
    }
    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('id', id)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    if (notes.length > 0) {
      return notes[0] as Note;
    }
    return null;
  }

  async getNoteByTitle(title: string): Promise<Note | null> {
    if (!this.userId) throw new Error('User not authenticated - getNoteByTitle');

    const { data: notes, error } = await this.db
      .from('Notes')
      .select()
      .eq('title', title)
      .eq('userId', this.userId) // extra check - even though row level security should be sufficient
      .is('deletedAt', null);
    if (error) throw error;
    if (notes.length > 0) {
      return notes[0] as Note;
    }
    return null;
  }

  async searchNotes(query: string, types?: TypeOfNote[], limit: number = 10): Promise<Note[]> {
    if (!this.userId) {
      throw new Error('User not authenticated - searchNotes');
    }
    let queryBuilder = this.db
      .from('Notes')
      .select()
      .eq('userId', this.userId)
      .is('deletedAt', null)
      .textSearch('title, value', query, { type: 'websearch' });

    if (types && types.length > 0) {
      queryBuilder = queryBuilder.in('type', types);
    }
    queryBuilder = queryBuilder.limit(limit);
    const { data: notes, error } = await queryBuilder;

    if (error) throw error;
    return notes as Note[];
  }

  // WRITE METHODS

  async updateNotes(notes: Note[]): Promise<void> {
    if (!this.userId) throw new Error('User not authenticated - updateNotes');
    // ensure authed userId
    const authEnsuredNotes = notes.map((change) => {
      return { ...change, userId: this.userId };
    });
    console.log('Supabase update notes', authEnsuredNotes.length, authEnsuredNotes);
    const { error } = await this.db.from('Notes').upsert(authEnsuredNotes);
    if (error) throw error;
  }

  async deleteNotes(notes: Note[]): Promise<void> {
    // Logical delete Note by setting the deletedAt field
    console.log('supabase deleting notes', notes.length, notes);
    const now = new Date().toISOString();
    const { error } = await this.db
      .from('Notes')
      .upsert(notes.map((note) => ({ ...note, userId: this.userId, deletedAt: note.deletedAt || now })));
    if (error) throw error;
  }
}
