import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { Note, UUID } from 'core/types';
import { useDragging } from 'src/context/DraggingContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { BrainContext } from 'src/context/BrainContext';

const HoverZone = styled.div<{ isTop: boolean }>`
  position: absolute;
  bottom: -5rem;
  left: 0;
  width: 39rem;
  height: 100%;
  z-index: ${(props) => 2000 + (props.isTop ? 1 : 0)};
  background-color: transparent;

  ${(props) =>
    props.isTop
      ? `height: 16rem;
    // background-color: rgba(146, 222, 222, 0.3);`
      : `height: 100%;
    // background-color: rgba(222, 146, 222, 0.3);`}
`;

interface CardDropzoneProps {
  linkTo: Note | null;
  afterId: UUID | null;
}

export const CardDropzone: React.FC<CardDropzoneProps> = ({ linkTo, afterId }) => {
  // isUserDragging - global state indicating that a user is dragging a card
  // setIsOverLinkToId - used to highlight the linkTo card
  const { isUserDragging, draggingNoteId, setHoveringDropzoneKey } = useDragging();
  const { showAafterB } = useContext(ShownIdsContext);
  const { linkNotes } = useContext(BrainContext);

  const isTop = afterId === null || afterId === linkTo?.id;

  const [{ isOver }, dropRef] = useDrop<Note, void, { isOver: boolean }>({
    accept: ['CARD', 'SMALLCARD'],
    canDrop: (item) => item.id !== linkTo?.id,
    drop: (item, monitor) => {
      if (monitor.didDrop()) return; // the item was dropped in a nested spot
      showAafterB(item.id, afterId || undefined);
      if (!linkTo) return; // nothing to link to
      if (item.links?.includes(linkTo.id) || item.id === linkTo.id) return; // already linked
      linkNotes(item, linkTo);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  });

  // highlight the linkTo card when hovered over something that'll link to it
  useEffect(() => {
    if (isOver) {
      setHoveringDropzoneKey(`${linkTo?.id}-${afterId}`);
    } else {
      // They've hovered away from the dropzone
      // If the state is still on this dropzone, change it to null
      setHoveringDropzoneKey((prev) => (prev === `${linkTo?.id}-${afterId}` ? null : prev));
    }
  }, [isOver, linkTo?.id, afterId, setHoveringDropzoneKey]);

  if (!isUserDragging) return null;
  // you can't link to yourself
  if (draggingNoteId === linkTo?.id) return null;

  return <HoverZone ref={dropRef} isTop={isTop}></HoverZone>;
};
