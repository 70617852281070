import styled from 'styled-components';
import { Note, Source, Takeaway } from 'core/types';
import { createRef, useState } from 'react';
import { colors } from 'core/styles';
import { useDragging } from 'src/context/DraggingContext';
import { SmallTakeawayCard } from './_components/SmallTakeawayCard';
import { SmallSourceCard } from './_components/SmallSourceCard';
import PreviewCard from './PreviewCard';

const DEFAULT_OFFSET_IN_REM = 0.7;
const CARD_OFFSET_IN_STACK_IN_REM = 0.7;

export const HiddenCardWrapper = styled.div<{ isOnBottom: boolean; offset: number }>`
  width: 100%;
  height: 60%;
  background: ${colors.bg.primary};
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  bottom: ${(props) =>
    props.isOnBottom ? `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem` : 'unset'};
  top: ${(props) =>
    props.isOnBottom ? 'unset' : `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem`};
  left: ${(props) =>
    props.isOnBottom ? `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem` : 'unset'};
  right: ${(props) =>
    props.isOnBottom ? 'unset' : `${-(DEFAULT_OFFSET_IN_REM + props.offset * CARD_OFFSET_IN_STACK_IN_REM)}rem`};

  transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out;

  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

type HiddenCardProps = {
  id: string;
  isOnBottom?: boolean;
  offset?: number;
  note: Note;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void | Promise<void>;
};

const HiddenCard: React.FC<HiddenCardProps> = ({ id, note, isOnBottom = false, offset = 0, onClick, className }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isUserDragging } = useDragging();
  const ref = createRef<HTMLDivElement>();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onClick(e, id);
  };

  // Hidden cards are not useful when a user is dragging
  if (isUserDragging) return null;

  return (
    <>
      <HiddenCardWrapper
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isOnBottom={isOnBottom}
        offset={offset}
        className={className}
        ref={ref}
      >
        {note.type === 'takeaway' && <SmallTakeawayCard takeaway={note as Takeaway} />}
        {note.type === 'source' && <SmallSourceCard source={note as Source} />}
      </HiddenCardWrapper>
      {isHovered && <PreviewCard note={note} isTriggerHovered={isHovered} triggerRef={ref} />}
    </>
  );
};

export default HiddenCard;
