import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { UUID, LinkInfo, Position } from 'core/types';
import { BrainContext } from 'src/context/BrainContext';
import { PortRefMap, getCenter } from 'src/helpers/ports';
import { Line } from './Line';
import UnlinkButton from './UnlinkButton';

export type LinksProps = {
  shownLinksInfo: LinkInfo[];
  portRefMap: PortRefMap;
};

export const SvgContainer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  pointer-events: none;
  /* background-color: rgba(0, 128, 0, 0.1); // Green color with 10% opacity */
`;

const ForegroundSvgContainer = styled(SvgContainer)`
  z-index: 2010;
  pointer-events: none;
`;

const getCenterPointOfGraphLine = (fromPos: Position, toPos: Position): Position => {
  const midX = (fromPos.x + toPos.x) / 2;
  const midY = (fromPos.y + toPos.y) / 2;
  const dx = toPos.x - fromPos.x;
  const dy = toPos.y - fromPos.y;

  // Adjust the control point by shifting it perpendicularly to the line segment
  const controlX = midX - dy * 0.1; // Adjust the 0.1 factor to increase or decrease curvature
  const controlY = midY + dx * 0.1;

  const midCurveX = 0.25 * fromPos.x + 0.5 * controlX + 0.25 * toPos.x;
  const midCurveY = 0.25 * fromPos.y + 0.5 * controlY + 0.25 * toPos.y;
  return { x: midCurveX, y: midCurveY };
};

// Best to render all links as a group so there's less race conditions
export const Links: React.FC<LinksProps> = ({ shownLinksInfo, portRefMap }) => {
  const [selectedLink, setSelectedLink] = useState<LinkInfo | null>(null);
  const [hoveredLink, setHoveredLink] = useState<LinkInfo | null>(null);
  const [buttonPos, setButtonPos] = useState<Position | null>(null);
  const [portPosMap, setPortPosMap] = useState<{ [key: UUID]: Position }>({});
  const [fromCardHeightsMap, setfromCardHeightsMap] = useState<{ [key: string]: number }>({});

  const { unlinkNotes } = useContext(BrainContext);

  // Set the positions of all ports
  useEffect(() => {
    const handleResize = (): void => {
      window.requestAnimationFrame(() => {
        // requestAnimationFrame ensures the DOM has updated before calculating positions
        const toolbarOffset = -330;
        const newPortPosMap: { [key: UUID]: Position } = {};

        const surfaceWrapper = document.getElementById('surface-wrapper');
        if (!surfaceWrapper) return;
        Object.keys(portRefMap).forEach((id) => {
          const el = portRefMap[id].current;
          if (el) {
            const centerPos = getCenter(el, toolbarOffset, surfaceWrapper);
            newPortPosMap[id] = centerPos;
          }
        });
        setPortPosMap(newPortPosMap);
      });
    };
    handleResize();

    const surfaceElement = document.getElementById('surface');
    if (surfaceElement) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(surfaceElement);

      return () => {
        resizeObserver.unobserve(surfaceElement);
        resizeObserver.disconnect();
      };
    }
    // If you can't do surface resize do window
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [portRefMap, shownLinksInfo]);

  // get the hover height for each link
  useEffect(() => {
    const newfromCardHeightsMap: { [key: string]: number } = {};
    shownLinksInfo.forEach((link) => {
      const fromCardHeight = (
        portRefMap[`link-${link.fromNote.id}`]?.current?.parentNode as HTMLElement
      )?.getBoundingClientRect().height;
      newfromCardHeightsMap[`${link.fromNote.id}-${link.toNote.id}`] = fromCardHeight;
    });
    setfromCardHeightsMap(newfromCardHeightsMap);
  }, [shownLinksInfo, portPosMap]);

  // Set the position of the "unlink" button if link is Selected
  useEffect(() => {
    if (buttonPos && !selectedLink) {
      setButtonPos(null);
      return;
    }
    if (!selectedLink) return;
    const { fromNote: selectedFromNote, toNote: selectedToNote, kind: selectedKind } = selectedLink;
    const fromPos = portPosMap[`link-${selectedFromNote.id}`];
    const toPos = portPosMap[`backlink-${selectedToNote.id}`];
    if (!fromPos || !toPos) return;
    // height of the fromCard
    const fromCardHeight = (
      portRefMap[`link-${selectedFromNote.id}`]?.current?.parentNode as HTMLElement
    )?.getBoundingClientRect().height;

    let newButtonPos = { x: (fromPos.x + toPos.x) / 2, y: fromPos.y - fromCardHeight / 2 };
    if (selectedKind === 'graph') {
      newButtonPos = getCenterPointOfGraphLine(fromPos, toPos);
    }
    if (newButtonPos !== buttonPos) setButtonPos(newButtonPos);
  }, [selectedLink, portPosMap, shownLinksInfo]);

  const toggleSelectLink = useCallback(
    (clickedLink: LinkInfo) => {
      setSelectedLink((prev) => {
        if (!prev || prev !== clickedLink) {
          return clickedLink;
        }
        return null; // disable if clicked off
      });
    },
    [setSelectedLink],
  );

  // Close the selected link if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (!selectedLink) return;
      let isOutside = true;
      const linkElements = document.querySelectorAll(`.link-line`);
      linkElements.forEach((element) => {
        if (element.contains(event.target as Node)) {
          isOutside = false;
        }
      });
      if (isOutside) {
        setSelectedLink(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedLink]);

  return (
    <>
      <SvgContainer id="surface-svg-background">
        {shownLinksInfo.map((link) => (
          <Line
            key={`${link.fromNote.id}-${link.toNote.id}`}
            kind={link.kind}
            fromPos={portPosMap[`link-${link.fromNote.id}`]}
            toPos={portPosMap[`backlink-${link.toNote.id}`]}
            inFront={false}
            hoverHeight={fromCardHeightsMap[`${link.fromNote.id}-${link.toNote.id}`]}
            onClick={() => toggleSelectLink(link)}
            onMouseEnter={() => setHoveredLink(link)}
            onMouseLeave={() => setHoveredLink(null)}
          />
        ))}
      </SvgContainer>

      <ForegroundSvgContainer id="surface-svg-foreground">
        {hoveredLink && (
          <Line
            fromPos={portPosMap[`link-${hoveredLink.fromNote.id}`]}
            toPos={portPosMap[`backlink-${hoveredLink.toNote.id}`]}
            inFront={true}
            kind={hoveredLink.kind}
            hoverHeight={fromCardHeightsMap[`${hoveredLink.fromNote.id}-${hoveredLink.toNote.id}`]}
            onClick={() => toggleSelectLink(hoveredLink)}
            onMouseEnter={() => setHoveredLink(hoveredLink)}
            onMouseLeave={() => setHoveredLink(null)}
          />
        )}
        {selectedLink && (
          <Line
            fromPos={portPosMap[`link-${selectedLink.fromNote.id}`]}
            toPos={portPosMap[`backlink-${selectedLink.toNote.id}`]}
            inFront={true}
            kind={selectedLink.kind}
            hoverHeight={fromCardHeightsMap[`${selectedLink.fromNote.id}-${selectedLink.toNote.id}`]}
            onClick={() => toggleSelectLink(selectedLink)}
            onMouseEnter={() => setHoveredLink(selectedLink)}
            onMouseLeave={() => setHoveredLink(null)}
          />
        )}
      </ForegroundSvgContainer>
      {buttonPos && selectedLink && (
        <UnlinkButton
          centerPosition={buttonPos}
          onClick={() => {
            unlinkNotes(selectedLink.fromNote, selectedLink.toNote);
            setSelectedLink(null);
          }}
        />
      )}
    </>
  );
};
