import { createContext, useContext, useEffect, useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import * as Sentry from '@sentry/react';
import { Profile } from 'core/types';
import { dexieDB } from 'core/dbs/dexieDb';
import { supabaseClient } from 'src/db/supabase';
import { useNetworkStatus } from './NetworkStatus';
import ProfileProvider from './ProfileContext';

interface AuthContextProps {
  session: Session | null;
  isOnline: boolean;
  logout: () => Promise<void>;
  getAndSetSession: () => Promise<boolean>;
}
const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const isOnline = useNetworkStatus();

  const setUserForSentry = (user: User | null): void => {
    if (user?.id) {
      Sentry.setUser({ id: user.id, email: user.email });
    } else {
      Sentry.setUser(null);
    }
  };

  // call to check for a session and set it and userId
  const getAndSetSession = async (): Promise<boolean> => {
    if (!isOnline) {
      // Check if there's atleast a local profile we can use
      const profile = (await dexieDB.getMetadata('profile')) as Profile;
      if (!profile) {
        console.error('No local profile found in indexedDB and offline', profile, 'isOnline', isOnline);
        return false;
      }
      console.log('OFFLINE - got Profile and userId', profile.userId);
      setUserId(profile.userId);
      return true;
    }

    // Fetch a session from Supabase
    const {
      data: { session: sessionToSet },
      error,
    } = await supabaseClient.auth.getSession();
    if (error) {
      console.error('Error fetching getAndSetSession session', error);
      return false;
    }

    setSession(sessionToSet);
    setUserId(sessionToSet?.user?.id || null);
    setUserForSentry(sessionToSet?.user || null);
    return !!sessionToSet?.user?.id;
  };

  useEffect(() => {
    getAndSetSession(); // has to be outside function as it's async
  }, []);

  useEffect(() => {
    console.log('online status changed to isOnline=', isOnline);
    getAndSetSession(); // has to be outside function as it's async
  }, [isOnline]);

  const logout = async (): Promise<void> => {
    setSession(null);
    setUserId(null);
    dexieDB.clear();
    supabaseClient.auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ session, isOnline, logout, getAndSetSession }}>
      <ProfileProvider userId={userId}>{children}</ProfileProvider>
    </AuthContext.Provider>
  );
};

// Hook for easy use of context
export const useAuthContext = (): AuthContextProps => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
