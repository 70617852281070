import { Dexie } from 'dexie';
import { DexieDatabase } from 'core/dbs/dexieDb';
import { SupabaseDatabase } from 'core/dbs/supabaseDb';

let dexieDB: DexieDatabase | null = null;
let supabaseDB: SupabaseDatabase | null = null;
let isInitStarted = false;

const sendUpdates = async (): Promise<void> => {
  if (!dexieDB || !supabaseDB) return;
  const { db } = dexieDB;
  try {
    await db.transaction('rw', db.changesToNotes, async () => {
      if (!supabaseDB) {
        throw new Error('Supabase DB not initialized during transaction');
      }
      const noteChanges = await db.changesToNotes.toArray();
      if (noteChanges.length > 0) {
        console.log('sending these note changes to server.  userid', supabaseDB!.userId, 'changes:', noteChanges);
        await Dexie.waitFor(supabaseDB.updateNotes(noteChanges));
        await db.changesToNotes.clear(); // Clear only after successful sync
      }
    });
  } catch (error) {
    // TODO - this is not very DRY.
    // This is mostly here as a temp debugging helper though to try individual notes if a mass note sync errors.
    console.error('Error syncing notes', JSON.stringify(error));
    console.log('try syncing each note individually if the bulk update fails');

    const rawNoteChanges = await db.changesToNotes.toArray();
    if (supabaseDB?.userId === undefined) {
      throw new Error('Supabase DB not initialized during transaction');
    }
    // ensure authed userId
    const noteChanges = rawNoteChanges.map((change) => {
      return { ...change, userId: supabaseDB!.userId };
    });

    await noteChanges.reduce(async (previousPromise, change) => {
      if (!supabaseDB) {
        throw new Error('Supabase DB not initialized during transaction');
      }
      await previousPromise;
      console.log('sending this note change to server', change);
      try {
        await supabaseDB.updateNotes([change]);
        await db.changesToNotes.delete(change.id);
      } catch (innerError) {
        console.error('Error syncing this note:', JSON.stringify(change), JSON.stringify(innerError));
      }
    }, Promise.resolve()); // Initial value is a resolved promise
  }
};

// Fetches anything new from the server
const fetchUpdates = async (): Promise<void> => {
  if (!dexieDB || !supabaseDB) {
    console.error('Trying to fetchUpdates but Dexie or Supabase DB not initialized');
    return;
  }
  const newSyncTime = new Date().toISOString();
  const lastSyncedAt = await dexieDB.getLastSyncTime();

  // Update notes since the last sync with the server
  const { data: notes, error } = await supabaseDB.db
    .from('Notes')
    .select()
    .gt('syncedAt', lastSyncedAt)
    .is('deletedAt', null);
  if (error) throw error;
  if (notes.length > 0) {
    console.log('received these notes from server', notes);
    await dexieDB.db.notes.bulkPut(notes);
    if (notes.length > 10 && dexieDB.searchModule) {
      // rebuild the search index if there are a lot of note
      await dexieDB.searchModule.buildIndex();
    }
  }
  dexieDB.updateLastSyncTime(newSyncTime);
};

// send updates from loacl dexieDB to server and then fetch any updates from server
export const syncWithServer = async (): Promise<void> => {
  if (!dexieDB || !supabaseDB) {
    console.error('Trying to syncDataWithServer but Dexie or Supabase DB not initialized');
    return;
  }
  await sendUpdates();
  await fetchUpdates();
};

// TODO - we could make dexie and supabase abstracted and just be dbA and dbB
export const initAndSync = async (dexie: DexieDatabase, supabase: SupabaseDatabase): Promise<void> => {
  dexieDB = dexie;
  supabaseDB = supabase;
  if (isInitStarted) return;
  isInitStarted = true;
  await dexieDB.initFromSelf();
  await supabaseDB.initFromSelf();
  syncWithServer();
};
