import { history, undo, redo } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { splitListItem } from 'prosemirror-schema-list';

import {
  baseKeymap,
  chainCommands,
  newlineInCode,
  createParagraphNear,
  liftEmptyBlock,
  splitBlock,
} from 'prosemirror-commands';
// TODO - the gap Cursor has a gapcursor.css that's required with it
import { gapCursor } from 'prosemirror-gapcursor'; // better cursor behavior
// import { dropCursor } from 'prosemirror-dropcursor'; // better cursor for drag and dropping
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { inputRules } from 'prosemirror-inputrules';
import { schema } from './schema';
import { autocompletPlugin } from './suggestPlugin';
import { parser } from './markdown';
import { suggestMenu } from './suggestMenu';
import { rules } from './rules';
import { Note } from '../../types';
import linkSelectedPlugin from './linkSelectedPlugin';

const handleEnterForSuggestMenu = (): boolean => {
  if (suggestMenu.isShown()) {
    suggestMenu.chooseSelected();
    return true;
  }
  return false;
};

// extra keys
const extraKeymap = {
  'Mod-z': undo,
  'Mod-y': redo,
  'Mod-Shift-z': redo,

  ArrowDown: () => {
    if (suggestMenu.isShown()) {
      suggestMenu.selectNext();
      return true;
    }
    return false;
  },
  ArrowUp: () => {
    if (suggestMenu.isShown()) {
      suggestMenu.selectPrevious();
      return true;
    }
    return false;
  },
  Enter: chainCommands(
    handleEnterForSuggestMenu,
    newlineInCode,
    splitListItem(schema.nodes.list_item),
    createParagraphNear,
    liftEmptyBlock,
    splitBlock,
  ),

  Escape: () => {
    if (suggestMenu.isShown()) {
      suggestMenu.hide();
      return true;
    }
    return false;
  },
};

// returns a new editor view
const createEditor = (
  container: HTMLElement,
  note: Note,
  placeholder = 'Add a comment...',
  focusedPlaceholder = '',
  options = {},
): EditorView => {
  // Put in a zero space character by default to make the cursor work
  const doc = parser.parse(`${note.value}\u200B`) || undefined;

  const state = EditorState.create({
    doc,
    plugins: [
      inputRules({ rules }),
      autocompletPlugin({ note }),
      keymap({ ...baseKeymap, ...extraKeymap }),
      history(),
      gapCursor(),
      linkSelectedPlugin({ note }),
    ],
  });

  const view = new EditorView(container, {
    state,
    dispatchTransaction(transaction) {
      const newState = view.state.apply(transaction);
      view.updateState(newState);
    },
    handleKeyDown: (v, event) => {
      // Stop propagation of keyboard events
      event.stopPropagation();
      return false; // Let ProseMirror handle the event
    },

    ...options,
  });

  view.dom.setAttribute('data-placeholder', placeholder);
  if (focusedPlaceholder.length > 0) view.dom.setAttribute('data-focusedPlaceholder', focusedPlaceholder);

  const updateEmptyClass = (): void => {
    const content = view.state.doc.textContent;
    if (content === '\u200B') {
      view.dom.classList.add('empty');
    } else if (view.dom.classList.contains('empty')) {
      view.dom.classList.remove('empty');
    }
  };
  // Listener for input events to add/remove the 'empty' class for
  view.dom.addEventListener('input', updateEmptyClass);
  view.dom.addEventListener('paste', updateEmptyClass);
  updateEmptyClass();
  return view; // Returning the view may be useful for further manipulation
};

export { createEditor, EditorView };
