import styled from 'styled-components';
import { colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  top: 1.5rem;
  right: 1.5rem;
  padding: 1.375rem;
  cursor: pointer;
  border-radius: 6px;
  background: ${colors.bg.primary};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(15, 15, 15, 0.16);
  z-index: 1000000;
`;

type SettingsButtonProps = {
  onClick: () => void;
};

const SettingsButton: React.FC<SettingsButtonProps> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon type={IconOption.Settings} size="2.25rem" />
    </Wrapper>
  );
};

export default SettingsButton;
