import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Note, UUID } from 'core/types';
import { BrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { colors, text } from 'core/styles';
import { FocusContext } from 'src/context/FocusContext';

const Button = styled.div<{ forceShow?: boolean }>`
  cursor: pointer;
  ${(props) => (props.forceShow ? 'display: block;' : 'display: none;')}
  position: relative;
  /* top: 9rem; */
  /* left: 10rem; */
  padding: 1rem;
  padding-left: 2rem;
  width: 32rem;
  color: ${colors.text.placeholder};
  background-color: ${colors.bg.primary};
  border-bottom: 1px solid ${colors.lines.indexRed};
  opacity: 0.8;
  &:hover {
    color: ${colors.text.link};
    box-shadow: 0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1);
    opacity: 1;
    z-index: 4000;
  }
  box-shadow: 0px 1px 2px rgba(15, 15, 15, 0.1);
`;

const Wrapper = styled.div`
  position: relative;
  font-size: ${text.size.primary};
  white-space: nowrap;
  margin-left: -4px;
  font-family: ${text.family.primary};
  color: ${colors.text.placeholder};
  z-index: 5;
`;

const AddNoteHoverZone = styled.div`
  position: relative;
  width: 35rem;
  height: 24px;
  /* top: -10rem; */
  pointer-events: auto;
  /* background-color: rgba(0, 0, 0, 0.2); */
  &:hover ${Button} {
    display: block;
  }
`;

export const AddTakeawayButton: React.FC<{
  linkToNote: Note | null;
  afterId?: UUID;
}> = ({ linkToNote, afterId }) => {
  const { createTakeaway } = useContext(BrainContext);
  const { showAafterB } = useContext(ShownIdsContext);
  const { focusedNoteId } = useContext(FocusContext);

  const addTakeaway = async (): Promise<void> => {
    const newTakeaway = await createTakeaway(linkToNote || undefined);
    showAafterB(newTakeaway.id, afterId);
  };

  // show the + Note next to any focused card
  const forceShow = useMemo(
    () => afterId === linkToNote?.id && focusedNoteId === linkToNote?.id,
    [afterId, linkToNote, focusedNoteId],
  );

  return (
    <Wrapper>
      <AddNoteHoverZone>
        <Button onClick={addTakeaway} forceShow={forceShow}>
          + Note
        </Button>
      </AddNoteHoverZone>
    </Wrapper>
  );
};
