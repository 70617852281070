/* eslint-disable no-param-reassign */
// The Brain keeps track of all the Notes and their state

// TODO - make all the main context functions not async (all in memory)
import { useState, createContext, ReactNode, useEffect, useContext } from 'react';
import { Note, TypeOfNote, UUID, NoteMap, SearchResults } from 'core/types';
import { syncWithServer, initAndSync } from 'core/dbs/sync';
import { SupabaseDatabase } from 'core/dbs/supabaseDb';
import { dexieDB } from 'core/dbs/dexieDb';
import { createNote } from 'core/utils/notes';
import { supabaseClient } from 'src/db/supabase';
// import { MiniSearchManager } from 'core/dbs/search/miniSearchManager';
import { FocusContext } from './FocusContext';
import { useNetworkStatus } from './NetworkStatus';
import { ProfileContext } from './ProfileContext';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - need two instances of this because it's cross module
const supabaseDB = new SupabaseDatabase(supabaseClient);

// Define the interface for the context value
interface BrainContextType {
  createTakeaway: (fromNote?: Note) => Note;
  updateNotes: (notes: Note[]) => void;
  linkNotes: (fromNote: Note, toNote: Note) => [Note, Note];
  unlinkNotes: (fromNote: Note, toNote: Note) => void;
  searchNotes: (query: string, types?: TypeOfNote[], limit?: number) => Promise<SearchResults>;
  getNoteMapByIds: (noteIds: UUID[]) => Promise<NoteMap>;
  getNoteByUrl: (url: string) => Promise<Note | null>;
}

interface BrainProviderProps {
  children: ReactNode;
}

const defaultContext: BrainContextType = {
  createTakeaway: () => {
    throw new Error('createTakeaway function not implemented');
  },
  updateNotes: dexieDB.updateNotes,
  linkNotes: () => {
    throw new Error('connectNotes function not implemented');
  },
  unlinkNotes: () => {
    throw new Error('disconnectNotes function not implemented');
  },
  searchNotes: dexieDB.searchNotes,
  getNoteMapByIds: () => {
    throw new Error('getNoteMapByIds function not implemented');
  },
  getNoteByUrl: () => {
    throw new Error('getLiveNoteByUrl function not implemented');
  },
};
export const BrainContext = createContext<BrainContextType>(defaultContext);

export const BrainProvider: React.FC<BrainProviderProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { setFocus } = useContext(FocusContext);
  const { profile } = useContext(ProfileContext);

  const isOnline = useNetworkStatus();

  const getNoteMapByIds = async (noteIds: string[]): Promise<NoteMap> => {
    const notes = await dexieDB.db.notes.where('id').anyOf(noteIds).toArray();
    return notes.reduce((noteMap, note) => {
      noteMap[note.id] = note;
      return noteMap;
    }, {} as NoteMap);
  };

  const getNoteByUrl = async (url: string): Promise<Note | null> => {
    const note = await dexieDB.db.notes
      .where('url')
      .equals(url)
      .and((n) => n.type === 'source')
      .first();
    return note || null;
  };

  const linkNotes = (fromNote: Note, toNote: Note): [Note, Note] => {
    if (!fromNote.links.includes(toNote.id)) {
      const updatedFromNote = {
        ...fromNote,
        links: [toNote.id, ...fromNote.links],
      };
      const updatedToNote = {
        ...toNote,
        backlinks: [fromNote.id, ...toNote.backlinks],
      };
      dexieDB.updateNotes([updatedFromNote, updatedToNote]);
      return [updatedFromNote, updatedToNote];
    }
    return [fromNote, toNote];
  };

  const unlinkNotes = (fromNote: Note, toNote: Note): void => {
    const newFromLinks = fromNote.links.filter((id) => id !== toNote.id);
    const newToBacklinks = toNote.backlinks.filter((id) => id !== fromNote.id);
    dexieDB.updateNotes([
      { ...fromNote, links: newFromLinks },
      { ...toNote, backlinks: newToBacklinks },
    ]);
  };

  const createTakeaway = (creatingNote?: Note): Note => {
    const newNote = createNote('', 'takeaway');
    setTimeout(() => setFocus(newNote.id), 0);
    console.log('creating takeaway from', creatingNote);
    if (!creatingNote) {
      dexieDB.updateNotes([newNote]);
      return newNote;
    }

    // it was created by another note so let's link them
    newNote.createdFromId = creatingNote.id;
    if (creatingNote.type === 'annote') {
      // Takeaways from annotes should start with the same value and links
      newNote.value = creatingNote.value;
      newNote.links = creatingNote.links;
      // add backlinks to this new note
      // TODO - this isn't the most reliable way to enruse these backlinks
      newNote.links.forEach((id) => {
        dexieDB.getNoteById(id).then((n) => {
          if (!n) return;
          const updatedNote = { ...n, backlinks: [newNote.id, ...n.backlinks] };
          dexieDB.updateNotes([updatedNote]);
        });
      });
    }
    console.log('link notes', newNote, creatingNote);
    // link Notes also saves them
    const [newFromNote] = linkNotes(newNote, creatingNote);
    return newFromNote;
  };

  // If a user comes back online, check for updates
  useEffect(() => {
    if (!isOnline) return;
    if (!isLoaded) return;
    syncWithServer();
  }, [isOnline, isLoaded]);

  useEffect(() => {
    // Add the custom search module
    if (!profile?.userId) return;

    // Disabling mini search manual as _basicSearch improved to 300ms
    // if (dexieDB.searchModule) return; // check if this has already been run
    // const miniSearchManager = new MiniSearchManager(dexieDB.db);
    // dexieDB.addSearchModule(miniSearchManager);

    initAndSync(dexieDB, supabaseDB);
    console.log('syncing initialized');
    // TODO - may not need this isLoading state anymore
    setIsLoaded(true);
  }, [profile?.userId]);

  const brain: BrainContextType = {
    searchNotes: dexieDB.searchNotes,
    getNoteMapByIds,
    updateNotes: dexieDB.updateNotes,
    getNoteByUrl,
    linkNotes,
    unlinkNotes,
    createTakeaway,
  };

  return <BrainContext.Provider value={brain}>{isLoaded ? children : <div>Loading...</div>}</BrainContext.Provider>;
};
