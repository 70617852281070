import { useState, useEffect } from 'react';

export const useNetworkStatus = (): boolean => {
  // State to store the online status
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Function to handle the 'online' event
    const goOnline = (): void => {
      setIsOnline(true);
    };

    // Function to handle the 'offline' event
    const goOffline = (): void => {
      setIsOnline(false);
    };

    // Adding event listeners for online and offline events
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return isOnline;
};
