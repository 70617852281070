import React, { createContext, useState, useEffect, useContext } from 'react';
import { Profile, UUID } from 'core/src/types';
import { dexieDB } from 'core/dbs/dexieDb';
import { supabaseClient } from 'src/db/supabase';
import { useNetworkStatus } from './NetworkStatus';

interface ProfileContextProps {
  profile: Profile | null;
  updateProfile: (updatedProfile: Profile) => Promise<void>;
}

export const ProfileContext = createContext<ProfileContextProps>({} as ProfileContextProps);

const ProfileProvider: React.FC<{ userId: UUID | null; children: React.ReactNode }> = ({ userId, children }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const isOnline = useNetworkStatus();

  // Tell the extension the latest date of the profile syncedAt
  const signalProfileLatestToExtension = (): void => {
    const latestSyncedAt = new Date().toISOString();
    window.postMessage({ action: 'profileLatestSynced', latestSyncedAt }, window.location.origin);
  };

  // Consider this function purely internal now
  const fetchAndSetProfile = async (
    minFreshDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
  ): Promise<void> => {
    console.log('fetching and setting profile', userId, minFreshDate);
    if (!userId) {
      setProfile(null);
      return;
    }

    // Check if a fresh enough one is in the local storage
    const localProfile = (await dexieDB.getMetadata('profile')) as Profile;
    if (localProfile) {
      const savedAt = await dexieDB.getMetadataSavedAt('profile');
      if (!isOnline || (savedAt && savedAt > minFreshDate)) {
        setProfile(localProfile);
        return;
      }
    }

    // There isn't a fresh enough profile in the local storage, so fetch it from the server
    const { data, error } = await supabaseClient.from('Profiles').select('*').eq('userId', userId).single();
    if (error || !data) {
      console.error('Failed to fetch profile from supabase', error);
      return;
    }
    const profileData: Profile = data as Profile;
    await dexieDB.setMetadata('profile', profileData);
    setProfile(profileData);
  };

  const updateProfile = async (updatedProfile: Profile): Promise<void> => {
    // Optimistically write it to the local state
    setProfile(updatedProfile);
    await dexieDB.setMetadata('profile', updatedProfile);
    // Write it
    await supabaseClient.from('Profiles').update(updatedProfile).eq('userId', userId);
    // Fetch it
    await fetchAndSetProfile();

    // Tell the extension that there's an updated profile
    signalProfileLatestToExtension();
  };

  useEffect(() => {
    if (userId && userId !== profile?.userId) fetchAndSetProfile();
  }, [userId, profile]);

  return <ProfileContext.Provider value={{ profile, updateProfile }}>{children}</ProfileContext.Provider>;
};

export const useProfileContext = (): ProfileContextProps => useContext(ProfileContext);

export default ProfileProvider;
