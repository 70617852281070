// Notes Database abstraction for IndexedDB
// Utilizing dexie.js - a wrapper around IndexedDB
import { Note, TypeOfNote, UUID, Profile, SearchResults } from '../types';
import { IDatabase, Metadata } from './types';
import { NotesDB } from './notesDb';
import { SearchModule } from './search';

const WEIGHTED_SEARCH_BY_TYPE: Record<TypeOfNote, number> = {
  takeaway: 5,
  source: 1,
  annote: 3,
};

// TODO list for dexiedb
// - remove deletedAt notes from the db and don't index on it
// - make links and backlinks of type set
// - maybe use "search" for getNotesByTitle and then not index by title

// helper function to ensure links and backlinks aren't duplicated
const removeDuplicates = (array: string[]): string[] => {
  return [...new Set(array)];
};

export class DexieDatabase implements IDatabase {
  db: NotesDB;

  searchModule?: SearchModule;

  userId?: UUID;

  private isUrlBlocked?: (url: string) => boolean;

  syncWithServer?: () => Promise<void> | undefined;

  constructor() {
    this.db = new NotesDB();

    // Bind methods
    this.searchNotes = this.searchNotes.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getNoteById = this.getNoteById.bind(this);
    this.getNoteByTitle = this.getNoteByTitle.bind(this);
    this.getNotesByUrl = this.getNotesByUrl.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.deleteNotes = this.deleteNotes.bind(this);
    this.initFromSelf = this.initFromSelf.bind(this);
    this.getMetadata = this.getMetadata.bind(this);
  }

  addSearchModule(searchModule: SearchModule): void {
    this.searchModule = searchModule;
  }

  // The profile info needed should be inside the DB so initialize it from there
  // cache the userId and isURLBlocked function
  async initFromSelf(): Promise<void> {
    const profile = (await this.getMetadata('profile')) as Profile | null;
    console.log('dexie got profile?', profile);
    if (!profile) return;

    const isUrlBlocked = (url: string): boolean => {
      const { host } = new URL(url);
      return profile?.blockedHosts.includes(host);
    };
    this.userId = profile.userId;
    this.isUrlBlocked = isUrlBlocked;
  }

  async updateLastSyncTime(syncTime: string = new Date().toISOString()): Promise<void> {
    await this.db.metadata.put({ key: 'lastSync', value: syncTime });
  }

  async getLastSyncTime(): Promise<string | null> {
    const lastSync = await this.db.metadata.get('lastSync');
    return lastSync ? lastSync.value : '2023-01-01T00:00:00.000Z';
  }

  async getNotes(): Promise<Note[]> {
    return this.db.notes.toArray();
  }

  async getNotesByUrl(url: string): Promise<{ notes: Note[]; isBlocked: boolean }> {
    if (this.isUrlBlocked && this.isUrlBlocked(url)) return Promise.resolve({ notes: [], isBlocked: true });
    // TODO filter out deleted at notes
    const notes = await this.db.notes.where({ url }).toArray();
    return { notes, isBlocked: false };
  }

  async getNoteById(id: UUID): Promise<Note | null> {
    const n = await this.db.notes.get(id);
    if (!n) return null;
    n.backlinks = n.backlinks || []; // weird dexie issue
    n.links = n.links || [];
    return n;
  }

  // TODO - this might be better implemented with search if available
  // Then we don't have to index on title either
  async getNoteByTitle(title: string): Promise<Note | null> {
    const note = await this.db.notes.filter((n) => n?.title.toLowerCase() === title?.toLowerCase()).first();
    return note || null;
  }

  // When there is no search query - fill with the latest notes
  _getLatestNotes(limit: number = 100, types: TypeOfNote[] = ['takeaway', 'source']): Promise<Note[]> {
    // return (
    //   this.db.notes
    //     .where('syncedAt')
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     .equals(null as any)
    //     .or('syncedAt')
    //     .above(0)
    //     .reverse() // reverse the order (descending)
    //     .filter((n) => types.includes(n.type) && !n.deletedAt)
    //     .limit(limit)
    //     .toArray()
    // );
    // TODO - null syncedAt notes should come first - only really matters for offline mode
    console.log('dexieDB getting latest notes', types, limit);
    return this.db.notes
      .orderBy('syncedAt')
      .reverse() // Reverse the order (descending)
      .filter((n) => types.includes(n.type) && !n.deletedAt)
      .limit(limit)
      .toArray();
  }

  // Note - Without this scoring results are 18ms and with they are 300ms
  // These results and speeds honestly aren't bad.  May not need searchModule
  private async _basicSearch(query: string, types?: TypeOfNote[], limit: number = 10): Promise<Note[]> {
    console.log('WARNING! dexieDB basic search', query, types, limit);
    const lowercaseQuery = query.toLowerCase();

    return this.db.notes
      .filter((note) => {
        const matchesType = !types || types.includes(note.type);
        const matchesQuery =
          note.title.toLowerCase().includes(lowercaseQuery) || note.value.toLowerCase().includes(lowercaseQuery);
        return matchesType && matchesQuery && !note.deletedAt;
      })
      .toArray()
      .then((notes) => {
        // Calculate score for each note
        const scoredNotes = notes.map((note) => {
          const titleMatches = (note.title.toLowerCase().match(new RegExp(lowercaseQuery, 'g')) || []).length;
          const valueMatches = (note.value.toLowerCase().match(new RegExp(lowercaseQuery, 'g')) || []).length;
          const score =
            ((titleMatches / note.title.length) * 4 + valueMatches / note.value.length) *
            WEIGHTED_SEARCH_BY_TYPE[note.type];
          return { note, score };
        });

        // Sort by score (descending) and return the top 'limit' results
        return scoredNotes
          .sort((a, b) => b.score - a.score)
          .slice(0, limit)
          .map((item) => item.note);
      });
  }

  async searchNotes(query: string, types?: TypeOfNote[], limit: number = 10): Promise<SearchResults> {
    console.log('dexieDB searching notes', query, types, limit, 'db', this);
    const startTime = performance.now();

    if (query.length === 0) {
      const latestNotes = await this._getLatestNotes(limit, types);
      const endTime = performance.now();
      console.log(`Dexie: empty search took ${endTime - startTime} ms`);
      return latestNotes;
    }

    if (this.searchModule) {
      const results = await this.searchModule.searchNotes(query, types, limit);
      const endTime = performance.now();
      console.log(`Dexie: Search for '${query}' took ${endTime - startTime} ms`);
      return results;
    }

    const results = await this._basicSearch(query, types, limit);
    const endTime = performance.now();
    console.log(`Dexie: Basic search for '${query}' took ${endTime - startTime} ms`);
    return results;
  }

  // WRITE OPERATIONS

  async updateNotes(notes: Note[]): Promise<void> {
    console.log('dexiedb updating notes', notes.length, notes);
    // set syncedAt to null to indicate that the note is not synced
    const updatedNotes = notes.map((note) => ({
      ...note,
      syncedAt: null,
      // Ensure links and backlinks aren't duplicated
      links: removeDuplicates(note.links),
      backlinks: removeDuplicates(note.backlinks),
    }));

    // Update the search index if there is one
    if (this.searchModule) this.searchModule.updateNotes(updatedNotes);

    // new changes will overwrite any existing changes
    await this.db.changesToNotes.bulkPut(updatedNotes);
    await this.db.notes.bulkPut(updatedNotes);
    if (this.syncWithServer) this.syncWithServer();
  }

  async clear(): Promise<void> {
    console.log('dexiedb clearing db');
    await this.db.notes.clear();
    await this.db.changesToNotes.clear();
    await this.db.metadata.clear();
    this.searchModule = undefined;
  }

  // Logical delete Note by setting the deletedAt field
  async deleteNotes(notes: Note[]): Promise<void> {
    // TODO - also remove links and backlinks from other notes
    const now = new Date().toISOString();
    const deletedNotes = notes.map((note) => ({ ...note, deletedAt: now }));
    console.log('dexiedb deleting notes', deletedNotes.length, deletedNotes);

    if (this.searchModule) await this.searchModule.deleteNotes(notes);

    await this.db.changesToNotes.bulkPut(deletedNotes);
    const noteIds = notes.map((note) => note.id);
    this.db.notes.bulkDelete(noteIds);
    if (this.syncWithServer) this.syncWithServer();
  }

  async getMetadata(key: string): Promise<Metadata | null> {
    const result = await this.db.metadata.get(key);
    return result ? (JSON.parse(result.value) as Metadata) : null;
  }

  async setMetadata(key: string, value: Metadata): Promise<void> {
    await this.db.metadata.put({ key, value: JSON.stringify(value) });
    const savedAt = new Date().toISOString();
    await this.db.metadata.put({ key: `${key}-SavedAt`, value: savedAt });
  }

  async getMetadataSavedAt(key: string): Promise<string | null> {
    const result = await this.db.metadata.get(`${key}-SavedAt`);
    return result ? result.value : null;
  }
}

export const dexieDB = new DexieDatabase();
