import React, { useCallback, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Takeaway } from 'core/types';
import { text, colors } from 'core/styles';
import { ProseEditor } from 'src/components/ProseEditor';
import { BrainContext } from 'src/context/BrainContext';

export const TITLE_PLACEHOLDER = 'Untitled Note';
const VALUE_PLACEHOLDER = 'Describe what you have found out';
const FOCUSED_PLACEHOLDER = ` Press '#' to link to a note or article`;

// TODO this wrapper could actually go in the card element?
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  user-select: none;
`;

const FullWrapper = styled(Wrapper)`
  /* Add your styles here */
  width: 35rem;
  min-height: 168px;
`;

const TitleRow = styled.div`
  font-family: ${text.family.primary};
  font-weight: ${text.weight.bold};
  display: flex;
  flex-direction: row;
  padding: 2rem 2rem 1rem;
  border-bottom: 1px solid ${colors.lines.indexRed};
  pointer-events: auto;
`;

const Title = styled.textarea`
  font-family: ${text.family.primary};
  font-weight: ${text.weight.medium};
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: ${colors.text.header};
  flex: 1;
  resize: 'vertical';

  border: none;
  resize: none;
  overflow: hidden;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;

  &:empty::before {
    content: attr(placeholder);
    color: ${colors.text.header};
  }
`;

const ValueRow = styled.div`
  display: block;
  flex-direction: row;
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0px;
  flex: 1;
  pointer-events: auto;
  font-weight: ${text.weight.regular}; //400
  font-size: 14px;
  line-height: 20px;
  color: ${colors.text.primary};

  .ProseMirror {
    cursor: text;
    min-height: 100px; /* Ensures the editor has space to click into */
    width: 100%;
    display: block;
  }

  div.ProseMirror.empty::before {
    color: ${colors.text.placeholder};
    font-size: inherit;
  }
`;

const TakeawayCard: React.FC<{ takeaway: Takeaway }> = ({ takeaway }) => {
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const { updateNotes } = useContext(BrainContext);

  const debouncedUpdateNotes = useCallback(debounce(updateNotes, 500), [updateNotes]);

  const sizeTitle = useCallback(() => {
    if (!titleRef.current) return;
    titleRef.current.style.height = 'inherit';
    titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
  }, [titleRef]);

  const updateTitle = useCallback(() => {
    if (!titleRef.current) return;
    const title = titleRef.current.value;
    if (title === takeaway.title) return;
    sizeTitle();
    debouncedUpdateNotes([{ ...takeaway, title }]);
  }, [takeaway, titleRef, debouncedUpdateNotes]);

  // resize title on mount
  useEffect(() => {
    updateTitle();
  }, [updateTitle]);

  useEffect(() => {
    setTimeout(sizeTitle, 1);
  }, []);

  return (
    <FullWrapper>
      <TitleRow>
        <Title
          ref={titleRef}
          defaultValue={takeaway.title}
          contentEditable
          rows={1}
          onChange={updateTitle}
          placeholder={TITLE_PLACEHOLDER}
          suppressContentEditableWarning
        />
      </TitleRow>
      <ValueRow>
        <ProseEditor
          note={takeaway}
          placeholder={VALUE_PLACEHOLDER}
          focusedPlaceholder={FOCUSED_PLACEHOLDER}
        ></ProseEditor>
      </ValueRow>
    </FullWrapper>
  );
};

export default TakeawayCard;
