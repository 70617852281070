import React, { useEffect, useRef, useContext } from 'react';
import { createEditor, EditorView } from 'core/components/proseEditor';
import { getMarkdownFromView } from 'core/components/proseEditor/utils';
import { Note } from 'core/types';
import { initialize as initializeProseMirror } from 'core/components/proseEditor/db';
import { dexieDB } from 'core/dbs/dexieDb';
import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { BrainContext } from 'src/context/BrainContext';
import { FocusContext } from 'src/context/FocusContext';
import { suggestMenu } from 'core/components/proseEditor/suggestMenu';

initializeProseMirror(dexieDB);

const EditorContainer = styled.span`
  .ProseMirror {
    outline: none;
    white-space: pre-wrap; /* Preserve spaces and line breaks */
    word-wrap: break-word; /* Allow long words to be broken */
  }

  .ProseMirror.empty[data-placeholder]:before {
    content: attr(data-placeholder);
    font-family: ${text.family.primary};
    font-size: ${text.size.primary};
    font-weight: ${text.weight.regular};
    color: ${colors.text.placeholder};
    pointer-events: none;
    position: absolute;
  }

  .ProseMirror.ProseMirror-focused.empty[data-focusedplaceholder]:before {
    content: attr(data-focusedplaceholder);
  }
`;

export const ProseEditor = React.memo<{ note: Note; placeholder?: string; focusedPlaceholder?: string }>(
  ({ note, placeholder = '', focusedPlaceholder = '' }) => {
    const editorRef = useRef(null);
    const editorViewRef = useRef<EditorView | null>(null); // Correct type annotation
    const { focusedNoteId, focusedTarget } = useContext(FocusContext);

    const { updateNotes } = useContext(BrainContext);

    const handleBlur = async (): Promise<void> => {
      if (!editorViewRef.current) return;
      if (suggestMenu.recentlyClicked) return; // not a real blur if they clicked on a suggestion
      const newMarkdown = getMarkdownFromView(editorViewRef.current);
      if (newMarkdown === note.value) return;
      await updateNotes([{ ...note, value: newMarkdown }]);
    };

    useEffect(() => {
      if (!editorViewRef.current && editorRef.current) {
        editorViewRef.current = createEditor(editorRef.current, note, placeholder, focusedPlaceholder);
      }
      // Cleanup on component unmount
      return () => {
        if (editorViewRef.current) {
          editorViewRef.current.destroy();
          editorViewRef.current = null;
        }
      };
    }, []); // Empty dependency array to ensure it runs only once

    useEffect(() => {
      if (!editorViewRef.current) return;
      if (!editorRef.current) return;
      if (focusedNoteId !== note.id) return;
      // By default, focus the editor unless they've explicitly clicked on a different target
      if (focusedTarget && !(editorRef.current as HTMLElement).contains(focusedTarget)) return;

      editorViewRef.current.focus();
    }, [focusedNoteId, note.id]);

    return <EditorContainer ref={editorRef} onBlur={handleBlur} />;
  },
  (prevProps, nextProps) => {
    return prevProps.note.value === nextProps.note.value;
  },
);
