import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';

const Banner = styled.div`
  width: 100%;
  background-color: ${colors.bg.quote.primary};
  color: ${colors.text.primary};
  text-align: center;
  padding: 10px;
  z-index: 1000;
  font-size: 14px;
  position: absolute;
  width: 200px;
  top: 10px;
  right: 100px;
`;

const Text = styled.div`
  margin: 0;

  a {
    color: ${colors.text.link};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    color: ${colors.text.subtitle};
  }
`;

let isExtensionInstalled = false;

const ExtensionBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const checkForExtension = async (): Promise<void> => {
      try {
        await new Promise((resolve) => {
          const handleMessage = (event: MessageEvent): void => {
            if (event.data.action !== 'extensionInstalled') return;
            isExtensionInstalled = true;
            window.removeEventListener('message', handleMessage);
            setShowBanner(false);
            resolve({ installed: true });
          };
          window.addEventListener('message', handleMessage);
          window.postMessage({ action: 'checkExtension' }, window.location.origin);
          // Fallback to resolve false if no response after 2 seconds
          setTimeout(() => {
            window.removeEventListener('message', handleMessage);
            setShowBanner(!isExtensionInstalled);
            resolve({ installed: false });
          }, 3000);
        });
      } catch (error) {
        console.error('Error checking for extension:', error);
      }
    };

    const isBannerClosed = localStorage.getItem('isBannerClosed');
    if (!isBannerClosed) checkForExtension();
  }, []);

  const closeBanner = (): void => {
    setShowBanner(false);
    localStorage.setItem('isBannerClosed', 'true');
  };

  if (!showBanner) return <></>;

  return (
    <Banner>
      <Text>
        <p>Install our browser extension for the best experience!</p>
        <p>
          <a
            href="https://chromewebstore.google.com/detail/annote/becaeodideblenfemdnhjgbipehcphia?hl=en-US"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to add
          </a>
        </p>
        <CloseButton onClick={() => closeBanner()}>X</CloseButton>
      </Text>
    </Banner>
  );
};
export default ExtensionBanner;
